import { computed, inject, Injectable } from '@angular/core';
import { ProfileService } from '@services/profile/profile.service';
import { FALLBACK_ROOT_ROUTE, DEFAULT_ROUTE_PRIORITY, ACL_ROOT_ROUTES } from '@acl/acl-root-routes.const';
import { Entitlement } from '@type/entitlement';
import { UserType } from '@type/user';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  protected profileService = inject(ProfileService);
  protected userType = computed(() => this.profileService.currentUser()?.type);
  protected userEntitlements = computed(() => this.profileService.currentUser()?.entitlements || []);

  isUserType(type: UserType): boolean {
    return this.userType() === type;
  }
  isUserSystem(): boolean {
    return this.isUserType('SystemUser');
  }
  isUserInternal(): boolean {
    return this.isUserType('InternalUser');
  }
  isUserBusiness(): boolean {
    return this.isUserType('BusinessUser');
  }

  getDefaultRootRoute() {
    const userType = this.userType();

    if (!userType) {
      return FALLBACK_ROOT_ROUTE;
    }

    return DEFAULT_ROUTE_PRIORITY[userType].find((path) => this.isAllowedRootRoute(path)) || FALLBACK_ROOT_ROUTE;
  }

  isAllowedRootRoute(path: string): boolean {
    let allowed = true;

    if (!ACL_ROOT_ROUTES[path]) {
      return true;
    }

    const userType = this.userType();
    if (!userType) {
      return false;
    }
    const rule = ACL_ROOT_ROUTES[path][userType];
    if (typeof rule === 'boolean') {
      return rule;
    }

    if ('entitlement' in rule) {
      allowed = allowed && this.isAllowedEntitlement(rule.entitlement);
    } else if ('someEntitlement' in rule) {
      allowed = allowed && this.isAllowedSomeEntitlement(rule.someEntitlement);
    } else if ('allEntitlements' in rule) {
      allowed = allowed && this.isAllowedAllEntitlements(rule.allEntitlements);
    }

    return allowed;
  }

  isAllowedEntitlement(name: Entitlement['name']): boolean {
    return this.userEntitlements().some((entitlement) => entitlement.name === name);
  }

  isSystemOrAllowedEntitlement(name: Entitlement['name']): boolean {
    return this.isUserSystem() || this.userEntitlements().some((entitlement) => entitlement.name === name);
  }

  isAllowedSomeEntitlement(names: Entitlement['name'][]): boolean {
    const userEntitlements = this.userEntitlements();
    return names.some((askedName) => userEntitlements.some((entitlement) => entitlement.name === askedName));
  }

  isAllowedAllEntitlements(names: Entitlement['name'][]): boolean {
    const userEntitlements = this.userEntitlements();
    return names.every((askedName) => userEntitlements.some((entitlement) => entitlement.name === askedName));
  }
}
