import { UserType } from '@type/user';
import { Entitlement } from '@type/entitlement';

type ExactEntitlementAllowingRule = {
  entitlement: Entitlement['name'];
};
type SomeEntitlementAllowingRule = {
  someEntitlement: Entitlement['name'][];
};
type AllEntitlementAllowingRule = {
  allEntitlements: Entitlement['name'][];
};
export type EntitlementAllowingRule =
  | ExactEntitlementAllowingRule
  | SomeEntitlementAllowingRule
  | AllEntitlementAllowingRule;

export type RootRouteAllowRule = EntitlementAllowingRule | boolean;

export const ACL_ROOT_ROUTES: Record<string, Record<UserType, RootRouteAllowRule>> = {
  companies: {
    SystemUser: true,
    InternalUser: {
      someEntitlement: ['update_company', 'create_update_company'],
    },
    BusinessUser: false,
  },

  'internal-users': {
    SystemUser: true,
    InternalUser: {
      entitlement: 'create_update_iu',
    },
    BusinessUser: false,
  },

  users: {
    SystemUser: false,
    InternalUser: false,
    BusinessUser: {
      entitlement: 'admin_bu',
    },
  },
};

export const DEFAULT_ROUTE_PRIORITY: Record<UserType, string[]> = {
  SystemUser: ['companies', 'internal-users'],
  InternalUser: ['companies', 'internal-users'],
  BusinessUser: ['users'],
};
export const FALLBACK_ROOT_ROUTE = 'profile';
